<template>
  <section>
    <!--TABLE FUEL-->
    <b-row>
      <b-col md="12">
        <div class="d-flex flex-wrap match-height">
          <div class="col-12 col-md-6 pl-0">
            <b-card>
              <ul class="list-unstyled">
                <li class="mb-1">
                  <font-awesome-icon icon="map-marked-alt" />
                  {{ trip }}
                </li>
                <li class="mb-1">
                  <feather-icon icon="CalendarIcon" />
                  {{ $moment.utc(time).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}
                </li>
                <li class="mb-1">
                  <font-awesome-icon icon="plane" />
                  {{ tripAircraftName(aircraft) }}
                </li>
                <li class="mb-1">
                  <span> {{ $t('trip.hangar.footprint') }} : {{ aircraft.footPrint }} {{ $getMeasurementUnitItn('m') }}² </span>
                </li>
                <li class="mb-1">
                  <span> {{ $t('trip.hangar.height') }} : {{ aircraft.height }} {{ $getMeasurementUnitItn('m') }} </span>
                </li>
                <li class="mb-1">
                  <span> {{ $t('trip.hangar.wingspan') }} : {{ aircraft.wingspan }} {{ $getMeasurementUnitItn('m') }} </span>
                </li>
              </ul>
            </b-card>
          </div>
          <div class="col-12 col-md-6 p-0">
            <b-card>
              <validation-observer ref="dateFilterHangar" #default="{ handleSubmit }">
                <!-- Form -->
                <b-form ref="formEventRules" class="d-flex flex-wrap p-1" @submit.prevent="handleSubmit(onSubmit)">
                  <!-- Start DATE-->
                  <validation-provider #default="{ errors }" :name="$t('common.start_at')" class="validation-required col-7 p-0" rules="required">
                    <b-form-group :label="$t('common.start_at')" class="mb-1" label-for="arrival-date">
                      <b-form-datepicker
                        id="arrival-date"
                        v-model="eventData.start.date"
                        :max="eventData.end.date"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                        size="md"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Start HOUR UTC-->
                  <validation-provider #default="{ errors }" :name="$t('trip.event.eta_utc')" class="validation-required col-5 pr-0" rules="required">
                    <b-form-group :label="$t('trip.event.hour_utc')" class="" label-for="arrival-time">
                      <b-form-timepicker v-model="eventData.start.time" :state="errors.length > 0 ? false : null" placeholder="" size="md" />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <p v-if="eventData.start.date && eventData.start.time && tripStepEvent.airport">
                        {{ $t('common.local_time') }} :
                        {{
                          $moment
                            .utc(eventData.start.date + ' ' + eventData.start.time)
                            .utcOffset(tripStepEvent.airport.timeZone)
                            .format('ddd, MMM Do YYYY HH:mm')
                        }}
                      </p>
                    </b-form-group>
                  </validation-provider>

                  <!-- End DATE-->
                  <validation-provider #default="{ errors }" :name="$t('common.end_at')" class="validation-required col-7 p-0" rules="required">
                    <b-form-group :label="$t('common.end_at')" class="mb-1" label-for="departure-date">
                      <b-form-datepicker
                        id="departure-date"
                        v-model="eventData.end.date"
                        :min="eventData.start.date"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                        size="md"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- End HOUR UTC-->
                  <validation-provider #default="{ errors }" :name="$t('trip.event.etd_utc')" class="validation-required col-5 pr-0" rules="required">
                    <b-form-group :label="$t('trip.event.hour_utc')" label-for="departure-time">
                      <b-form-timepicker v-model="eventData.end.time" :state="errors.length > 0 ? false : null" placeholder="" size="md" />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <p v-if="eventData.end.date && eventData.end.time && tripStepEvent.airport">
                        {{ $t('common.local_time') }} :
                        {{
                          $moment
                            .utc(eventData.end.date + ' ' + eventData.end.time)
                            .utcOffset(tripStepEvent.airport.timeZone)
                            .format('ddd, MMM Do YYYY HH:mm')
                        }}
                      </p>
                    </b-form-group>
                  </validation-provider>

                  <div class="d-flex justify-content-end col-12 p-0 mt-1">
                    <b-overlay :show="loading || searchLoading">
                      <b-button loading="true" :disabled="searchLoading" @click="search()">
                        <feather-icon icon="SearchIcon" />
                        {{ $t('action.search') }}
                      </b-button>
                    </b-overlay>
                  </div>
                </b-form>
              </validation-observer>
            </b-card>
          </div>
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { fetchInfoForHangarRequest } from '@/request/globalApi/requests/apiRequests'
import { mapGetters, mapState } from 'vuex'
import TripHelper from '@/helpers/trip'

export default {
  name: 'HangarSearch',
  mixins: [TripHelper],
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    tripStepEvent: {
      type: Object,
      default: null,
    },
    searchLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      trip: {},
      time: '',
      aircraft: {},
      eventType: '',
      eventData: {
        start: {
          date: '',
          time: '',
        },
        end: {
          date: '',
          time: '',
        },
      },
      startAt: '',
      endAt: '',
      loadProducts: false,
    }
  },
  computed: {
    ...mapState('hangarSearch', ['tripStepEventHangarSearch']),
    ...mapGetters('hangarSearch', ['getStartDateByEvent', 'getStartTimeByEvent', 'getEndDateByEvent', 'getEndTimeByEvent']),
  },
  watch: {
    'eventData.start.date': {
      handler(val) {
        this.$store.commit('hangarSearch/SET_START_DATE', { tripStepEventId: this.$route.params.event_id, startDate: val })
      },
    },
    'eventData.start.time': {
      handler(val) {
        this.$store.commit('hangarSearch/SET_START_TIME', { tripStepEventId: this.$route.params.event_id, startTime: val })
      },
    },
    'eventData.end.date': {
      handler(val) {
        this.$store.commit('hangarSearch/SET_END_DATE', { tripStepEventId: this.$route.params.event_id, endDate: val })
      },
    },
    'eventData.end.time': {
      handler(val) {
        this.$store.commit('hangarSearch/SET_END_TIME', { tripStepEventId: this.$route.params.event_id, endTime: val })
      },
    },
  },
  beforeMount() {
    this.eventData.start.date = this.getStartDateByEvent(this.$route.params.event_id)
    this.eventData.start.time = this.getStartTimeByEvent(this.$route.params.event_id)
    this.eventData.end.date = this.getEndDateByEvent(this.$route.params.event_id)
    this.eventData.end.time = this.getEndTimeByEvent(this.$route.params.event_id)
  },

  mounted() {
    this.APIFetchInfoForHangar()
    if (this.$refs.dateFilterHangar
        && this.eventData.start.date !== null && this.eventData.start.time !== null
        && this.eventData.end.date !== null && this.eventData.end.time !== null) {
      this.search()
    }
  },
  methods: {
    APIFetchInfoForHangar() {
      this.loadProducts = true
      fetchInfoForHangarRequest(this.$route.params.event_id)
        .then(r => {
          const { eventType } = r.data
          this.eventType = eventType
          const { arrivalFromAddress, departureFromAddress } = r.data
          const { eta, etd } = r.data
          if (this.eventType === 'TripFlightDeparture') {
            this.trip = departureFromAddress
            this.time = etd
          } else {
            this.trip = arrivalFromAddress
            this.time = eta
          }

          // todo: check legs for start and end

          const { organizationAircraft } = r.data
          this.aircraft = organizationAircraft
        })
        .finally(() => {
          this.loadProducts = false
        })
    },
    async search() {
      await this.$refs.dateFilterHangar.validate().then(success => {
        if (success === true) {
          this.startAt = this.$moment.utc(`${this.eventData.start.date} ${this.eventData.start.time}`, 'YYYY-MM-DD HH:mm').utc(true).format()
          this.endAt = this.$moment.utc(`${this.eventData.end.date} ${this.eventData.end.time}`, 'YYYY-MM-DD HH:mm').utc(true).format()

          this.$emit('start', this.startAt)
          this.$emit('end', this.endAt)
          this.$emit('click:search-hangar')
        }
      })
    },
  },
}
</script>
