<template>
  <section>
    <!--HEADER-->
    <b-overlay :show="!tripData">
      <trip-view :trip-data="tripData" />
    </b-overlay>

    <hangar-search v-if="tripStepEvent && tripStepEvent.id" :loading="tripStepLoading" :search-loading="showLoading" :trip-step-event="tripStepEvent" @end="val => (paramsHangar.endAt = val)" @start="val => (paramsHangar.startAt = val)" @click:search-hangar="searchHangar()" />

    <b-overlay :show="organizationsLoading" class="my-3" spinner-medium>
      <b-row v-if="!organizations.length">
        <b-col cols="12">
          <b-alert class="m-0" show variant="primary">
            <div class="alert-body text-center">
              <span>{{ $t('alert.hangar.no_hangar_found') }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col md="12">
          <b-card v-for="organization in organizations" :key="organization.id">
            <b-col class="d-flex justify-content-start p-0">
              <div class="col-4 p-0">
                <b-img :src="organization.logoFileUrl ? organization.logoFileUrl : require('@/assets/images/logo/logo.png')" class="product-img" fluid width="300px" />
              </div>
              <div class="col-8 p-0">
                <h2 class="text-underline">
                  <b-link
                    :to="{
                      name: 'provider-details',
                      params: {
                        provider_id: organization.id,
                        trip_id: tripData.id,
                        step_id: tripStep.id,
                        event_id: tripStepEvent.id,
                        airport_id: tripStepEvent.airport.id,
                      },
                      query: {
                        currentNav: 'hangar'
                      }
                    }"
                  >
                    {{ organization.name }}
                  </b-link>
                </h2>
                <p class="provider-description" v-html="organization.description"></p>
              </div>
            </b-col>
            <div class="d-flex justify-content-center mb-1">
              <b-button
                v-b-toggle="'collapse_hangar-' + organization.id"
                v-ripple.400="'rgba(220, 193, 129, 0.15)'"
                :pressed="viewingOrganizationId === organization.id"
                :variant="`light`"
                class="mx-1"
                size="sm"
                @click="toggleHangarDetails(organization.id)"
              >

                {{ viewingOrganizationId === organization.id ? $t('trip.hangar.view_less') : $t('trip.hangar.view_hangars') }}
                <font-awesome-icon :icon="viewingOrganizationId === organization.id ? 'chevron-down' : 'chevron-up'" class="ml-50" size="sm" />
              </b-button>
            </div>
            <b-collapse :id="'collapse_hangar-' + organization.id">
              <b-overlay :show="showLoading">
                <b-card v-for="product in products[organization.id]" :key="product.id" class="border">
                <b-col class="d-flex justify-content-start p-0">
                  <div class="col-4 p-0">
                    <b-img
                      :src="
                        product.product.mainVariant.files.length && product.product.mainVariant.files[0].fileUrls[300] ? product.product.mainVariant.files[0].fileUrls[300] : require('@/assets/images/logo/logo.png')
                      "
                      class="product-img"
                      fluid
                    />
                  </div>
                  <div class="m-0 pr-0 col-8 d-flex flex-wrap align-items-start">
                    <div class="col-12">
                      <div class="d-flex align-items-start justify-content-end">
                        <b-badge v-if="product.available" class="badge-price" variant="success">
                          {{ $t('trip.hangar.available') }}
                        </b-badge>
                        <b-badge v-else class="badge-price" variant="warning">
                          {{ $t('trip.hangar.availability_subject_to_confirmation') }}
                        </b-badge>
                      </div>
                      <div>
                        <h3 class="text-underline">
                          <b-link
                            :to="{
                              name: 'hangar-detail',
                              params: {
                                provider_id: organization.id,
                                trip_id: tripData.id,
                                step_id: tripStep.id,
                                event_id: tripStepEvent.id,
                                product_id: product.product.id,
                                airport_id: tripStepEvent.airport.id,
                              },
                              query: {
                                start_at: paramsHangar.startAt,
                                end_at: paramsHangar.endAt,
                              },
                            }"
                          >
                            {{ product.product.name | trans }}
                          </b-link>
                        </h3>
                        <b-col class="col=12 pr-0 mt-1">
                          <div>
                            <h5 class="font-weight-bold">{{ $t('service.technical_informations.title') }}</h5>
                            <ul class="list-unstyled">
                              <li v-if="product.product.mainVariant.floorArea">{{ $t('service.technical_informations.net_floor_surface_for_rent') }} : {{ product.product.mainVariant.floorArea }} {{ $getMeasurementUnitItn('m') }}² </li>
                              <li v-if="product.product.mainVariant.parkingArea">{{ $t('service.technical_informations.net_floor_surface_for_rent') }} : {{ product.product.mainVariant.parkingArea }} {{ $getMeasurementUnitItn('m') }}² </li>
                              <li v-if="product.product.mainVariant.maxDoorHeight">{{ $t('service.technical_informations.door_opening_height') }} : {{ product.product.mainVariant.maxDoorHeight }} {{ $getMeasurementUnitItn('m') }} </li>
                              <li v-if="product.product.mainVariant.maxDoorWidth">{{ $t('service.technical_informations.door_opening_width') }} : {{ product.product.mainVariant.maxDoorWidth }} {{ $getMeasurementUnitItn('m') }} </li>
                              <li v-if="product.product.mainVariant.maxWingspan">{{ $t('service.technical_informations.wingspan_width') }} : {{ $t('common.from') }} {{ product.product.mainVariant.minWingspan }} {{ $t('common.to') }} {{ product.product.mainVariant.maxWingspan }} {{ $getMeasurementUnitItn('m') }} </li>
                              <li v-if="product.product.mainVariant.maxMtow">{{ $t('service.technical_informations.mtow') }} : {{ $t('common.from') }} {{ product.product.mainVariant.minMtow }} {{ $t('common.to') }} {{ product.product.mainVariant.maxMtow }} {{ $getMeasurementUnitItn('kg') }} </li>
                            </ul>
                            <div v-if="product.product.mainVariant && product.product.mainVariant.variantAttributes.length">
                              <div v-for="attribute in product.product.mainVariant.variantAttributes" :key="attribute.id">
                                <p v-if="attribute.inputValues">{{ attribute.attribute.name | trans }} : {{ attribute.inputValues | trans }}</p>
                                <p v-if="attribute.selectValue">
                                  <b-link :href="attribute.selectValue.iconFileUrl" target="_blank">
                                    <img v-if="attribute.selectValue.iconFileUrl" height="25px" width="25px" :src="attribute.selectValue.iconFileUrl" :alt="attribute.selectValue.name">
                                  </b-link>
                                  {{ attribute.attribute.name | trans }} : {{ attribute.selectValue.name | trans }}
                                </p>
                                <div v-if="attribute.choiceValues">
                                  <p v-for="choice in attribute.choiceValues">
                                    <b-link :href="choice.iconFileUrl" target="_blank">
                                      <img v-if="choice.iconFileUrl" height="25px" width="25px" :src="choice.iconFileUrl" :alt="choice.name">
                                    </b-link>
                                    {{ choice.name | trans }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h5 v-if="$route.query.start_at || $route.query.end_at" class="font-weight-bold">{{ $t('trip.hangar.time_of_stay') }}</h5>
                            <ul v-if="$route.query.start_at || $route.query.end_at" class="list-unstyled">
                              <li v-if="$route.query.start_at">{{ $t('common.start_at') }} : {{ $moment.utc($route.query.start_at).format('ddd, MMM Do YYYY HH:mm [UTC] ') }}</li>
                              <li v-if="$route.query.end_at">{{ $t('common.end_at') }} : {{ $moment.utc($route.query.end_at).format('ddd, MMM Do YYYY HH:mm [UTC] ') }}</li>
                            </ul>
                          </div>
                        </b-col>

                      </div>
                    </div>
                    <div class="col-12 align-self-end">
                      <b-col class="d-flex align-items-center justify-content-end p-0">
                        <h4 class="item-price m-0">
                          <span v-if="!product.product.minPrice" class="h5">
                            {{ $t('trip.shop.price_on_quote') }}
                          </span>
                          <span v-if="product.product.minPrice && product.product.mainVariant.calendarMode"> {{ product.product.minPrice.displayPriceExcludingTaxes | priceFormat }} {{ $t('trip.hangar.excl_taxes') }}</span>
                          <span v-if="product.product.minPrice && !product.product.mainVariant.calendarMode"> {{ product.product.minPrice.formattedPriceExcludingTaxes | priceFormat }} {{ $t('trip.hangar.excl_taxes') }}</span>
                        </h4>
                        <b-button v-if="!product.selected" class="btn-cart ml-1" variant="primary" @click="confirmation(product)">
                          <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                          <span>{{ $t('trip.shop.add_to_cart') }}</span>
                        </b-button>
                        <b-button
                          v-else
                          :to="{
                            name: 'shop-checkout',
                            params: {
                              provider_id: organization.id,
                              trip_id: $route.params.trip_id,
                              step_id: tripStep.id,
                              event_id: $route.params.event_id,
                              airport_id: tripStepEvent.airport.id,
                            },
                          }"
                          class="btn-cart ml-1"
                        >
                          <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                          <span>{{ $t('trip.shop.action.view_in_cart') }}</span>
                        </b-button>
                      </b-col>
                    </div>
                  </div>
                </b-col>
              </b-card>

                <div class="d-flex justify-content-center">
                  <b-button v-b-toggle="'collapse_hangar-' + organization.id" :variant="`light`" @click="toggleHangarDetails(organization.id)">
                    <font-awesome-icon :icon="'chevron-up'" class="" size="lg" />
                  </b-button>
                </div>
              </b-overlay>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <HangarConfirmation
      v-if="tripStepEvent"
      :confirmation-edition="confirmationEdition"
      :date="eventData"
      :selected-product="selectedProduct"
      :trip-step-event="tripStepEvent"
      @update:offer="postOffer($event)"
      @update:edition="confirmationEdition = $event"
    />
  </section>
</template>
<script>
import HangarSearch from '@/views/provider/hangar/HangarSearch.vue'
import number from '@/mixins/number'

import TripView from '@/views/trip/trip-view/TripView.vue'
import { fetchHangarOrganizationsRequest, fetchHangarProductRequest } from '@/request/globalApi/requests/apiRequests'
import HangarConfirmation from '@/views/provider/hangar/HangarConfirmation.vue'

export default {
  name: 'Hangar',
  components: {
    TripView,
    HangarConfirmation,
    HangarSearch,
  },
  mixins: [number],
  data() {
    return {
      tripData: {},
      tripStep: {},
      tripStepEvent: {},
      trip: {},
      time: '',
      aircraft: {},
      eventType: '',
      tripStepLoading: true,
      eventData: {
        start: {
          date: '',
          time: '',
        },
        end: {
          date: '',
          time: '',
        },
      },
      organizations: [],
      organizationsLoading: false,
      products: [],
      showLoading: false,
      viewingOrganizationId: null,

      organizationsIds: [],
      viewHangars: [],
      confirmationEdition: false,
      selectedProduct: null,
      paramsHangar: {
        organizationId: null,
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SORTING
        nameOrder: null,
        // SEARCH
        searchQuery: null,
        categoriesIds: null,
        startAt: '',
        endAt: '',
        selectedOrganizationId: 0,
      },
    }
  },
  mounted() {
    this.APIFetchTrip()
  },
  methods: {
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)
      const trip = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: true })
      if (trip) {
        this.tripData = trip
        trip.tripSteps.forEach(tripStep => {
          if (!this.getCurrentTripStep(tripStep, tripStep.flightDeparture)) {
            this.getCurrentTripStep(tripStep, tripStep.flightArrival)
          }
        })
      }
    },
    getCurrentTripStep(leg, event) {
      if (event !== undefined) {
        Object.entries(event).forEach(([key, val]) => {
          if (key === 'id' && val === parseInt(this.$route.params.event_id, 10)) {
            this.tripStepEvent = event
            this.tripStep = leg
            this.tripStepLoading = false
            return true
          }
          return false
        })
      }
    },
    searchHangar() {
      this.eventData.start.time = this.$moment.utc(this.paramsHangar.startAt).format('HH:mm')
      this.eventData.start.date = this.$moment.utc(this.paramsHangar.startAt).format('YYYY-MM-DD')
      this.eventData.end.time = this.$moment.utc(this.paramsHangar.endAt).format('HH:mm')
      this.eventData.end.date = this.$moment.utc(this.paramsHangar.endAt).format('YYYY-MM-DD')

      this.APIFetchHangarOrganizations()
    },
    APIFetchHangarOrganizations() {
      this.organizationsLoading = true
      this.organizations = []
      // Reset aussi des produits
      this.products = []

      fetchHangarOrganizationsRequest(this.tripStepEvent.id, this.paramsHangar)
        .then(r => {
          this.organizations = r.data.organizations
        })
        .finally(() => {
          this.organizationsLoading = false
        })
    },
    APIFetchHangar(organizationId) {
      this.products[organizationId] = []
      this.showLoading = true

      // Récupération des produits pour 1 vendeur
      this.paramsHangar.organizationId = organizationId
      fetchHangarProductRequest(this.tripStepEvent.id, this.paramsHangar)
        .then(r => {
          const { products } = r.data
          this.products[organizationId] = products
        })
        .finally(() => {
          this.showLoading = false
        })
    },
    confirmation(product) {
      this.selectedProduct = product.product
      this.confirmationEdition = true
    },
    postOffer() {
      // TODO: Use find() instead of forEach()
      this.products.forEach((organizationProduct, organizationProductIndex) => {
        organizationProduct.forEach((product, productIndex) => {
          if (this.selectedProduct.id === product.product.id) {
            this.products[organizationProductIndex][productIndex].selected = true
            this.$set(this.products, organizationProductIndex, this.products[organizationProductIndex])
          }
        })
      })
    },
    toggleHangarDetails(organizationId) {
      // View less
      if (this.viewingOrganizationId === organizationId) {
        this.viewingOrganizationId = null
      } else {
        // View more
        this.viewingOrganizationId = organizationId
        this.APIFetchHangar(organizationId)
      }
    },
  },
}
</script>
<style scoped>
.provider-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
